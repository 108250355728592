import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Fingerprint`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`4C71 7BDC 0E45 2F5B 1349 0409 AB4C 64C5 8BA9 C3ED
`}</code></pre>
    <h2>{`Public Key`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBFlH/DQBEADAeJyzOLiphDiCU3Y/9g5Y5f1NWVF8W2Oa185gf/j9MYCODUNA
x/yqyxfzWIyYqV3wH/28dAKWCQTXVyXQV2+TvXQ1/UotCKaIym0dG00H5vaibXUs
wscyTjwJScj3jzZBX698EdyuvvxIGeDk9ZesQhHxnguaP0ZYFQZvxtCOX//D0bmD
hbzSLpfhy0ALChwHKLk0wQCTMit1NqceHtqMAYyFzUgxJem8NJ7tSycW7UTZ5li1
N/A+a9rTXZ52ZAznGBPsCskvrU+7qpb3FXdT3AsmHJTN+IOFc+6cJPGebPnb6qFc
nd+Vgcyki4RecxZzzplV81ebQNNaX+B8zc4H/y+F6x2S7Y5hgpXCuXxfLFue37Cv
0MDwFxMGVLyDwnGS+nk+dnRAp1x8IdWo3X/QFhbm+1ZhNACCTIgS6drWSLXNRzAY
bTnIGjeS9FNz2IPDUYMWINiyv1Qpj9reisfj3txMlJuWM+SBEY3AhIbfK87nTRSc
SIGR62GkfMbElYr1FWEeWPcCtxm2a/Qe3kR8ckB+3O2Ml7QKbF+FOnLhfu1TxGzQ
Ntw0LVxx7QPhgWXcct3b+pYsC7p7KtjE+PctM4xfVZdvx8MrD1MfdS5evB4abde2
LKn5CoqZcu74r3EX43ObZl0Ayp4GIIESrvMJay4hIIkEPaR+cm8BpoDzMwARAQAB
tCJQZWRybyBMYW1hcyA8cGVkcm9sYW1hc0BnbWFpbC5jb20+iQJXBBMBCgBBAhsB
BQsJCAcDBRUKCQgLBRYCAwEAAh4BAheAAhkBFiEETHF73A5FL1sTSQQJq0xkxYup
w+0FAmZDT9oFCRC6cUoACgkQq0xkxYupw+0enRAAoXkGGZ3h52JLWCzO/fYZtkgh
bMsLCPvAKH9/k1sX5JMcXZEzY07zCNgjXf65BqZ0D8Dk65L7qO5hU9rr8MzBJzKS
Zpi8hMhOcNloH3zk75Z6C5CvPZc67CqCJxf9BH3Q1YdCGzA3sdzq5ghOAqlVt8pW
nyjKYW8FQhymyOQ5VTRO4gCZWC0sakTQqmWeHngiqr3Wp0xF2CkiobdFHDvzBZb3
l+QjiGVaiHHp2621bwBvNTiV13lz2ppoXHq60oB9Za5aEcFryVXqF/DCl6Ricf+l
ExKoWqf06eoICy7p4jIboa9Z+Ok2Q1eBYtGLSSJJKVBTGwohDocGx2nVwqDcXD5x
T7mH39kWN9RPCdI8iUhQpX0gxNc5n7iG4BaH4eJFGHMxSrsMr+iJlhNxSpxvKORe
70B9nqEVyDUUYEPrN6mJbiU11+QeHrOmSDoVyXEpyl22xtB7iwbp2Vw2ZgJ1sYom
rJe64YKDDwYshT1V1VfA4wdDND7MxyPUIICjFtQVft3ZkrcWrnedXMQt9aej8uyB
sPrMsHq26kmAE2BnoXmCgcS3PkTGBB0LKIVHcKScjzWLG8nPk7w+NyH7dcHVZ6ko
u7PZ6+Xqg9ttOJqueLpfJM67lkLNZ9ahma72qTJNi5GA23WKWVwZSQCfwPehKx55
JlhkYGQNSrX+3JWrQbe0JVBlZHJvIExhbWFzIDxwZWRyby5sYW1hc0BvdXRsb29r
LmNvbT6JAlQEEwEKAD4CGwEFCwkIBwMFFQoJCAsFFgIDAQACHgECF4AWIQRMcXvc
DkUvWxNJBAmrTGTFi6nD7QUCZkNP4wUJELpxSgAKCRCrTGTFi6nD7bWcD/90WEjz
M1AQd88xSe69/S3oZWCDQHVttgdFoLpvQons9YIFwksBgT9aPO/T5fx8ufQ51a/w
w4eK1GzVv8N+UTgWMJ+LQDETQrw9tHH2sN70SLDcFMDiqVGh6r7w6yxJLGUr9jfq
LtDD/Guqca2UmgbT64SSDqCo32oPzroQKLQ48xnM30LUWOridRNbV3+d6CbTnIK1
SPW+WEnK0C2kKNx+jCRmf1B4mueG3aTQe+Ew9csPCeiG5Mt/NZM+b/LznTP6mEgp
JIlwXG/C9QeHzlWtxx/4UvJx9o8cT4pyAO/SXCJ8VwdOD3lw+CTv6pGTTEIVz79D
rMXoHG7hGOE2X6zAr3gFtqzQ0H9oHEdwsOr2Vs+w7zZqgr5hV79RewWqCT1dkoBf
iDeUMdQ4PjzmXGq1Zxifv0FcSrMDUG/OU3uIdXbAD7DwuPeJznIDiIHdS0s0Ntrw
VyDTnuZI8GcsWR4OYk17wFgK0+CqGzbXW6b4MiUdtmRKTddcBeg2BeG6O6DEfuRB
uEOJiWc+neI9ElMT4rnP42T7bi+bAh1lOW2NwfeJT3wA9Sk+kvo4L4KeTdl2H327
l24ia8oz6YXY0FSVNWOY93fsOQOaMNiIzDkZ0Q8iGGgeWbtRyQ6D5xVXeDnNtRfj
cq3ox4Pa/kIs5Xz8PiTKk9z4SwQzjJyzytdfHLQiUGVkcm8gTGFtYXMgPHBlZHJv
QGFtcGxlcm9hZHMuY29tPokCVAQTAQoAPgIbAQULCQgHAwUVCgkICwUWAgMBAAIe
AQIXgBYhBExxe9wORS9bE0kECatMZMWLqcPtBQJmQ0/kBQkQunFKAAoJEKtMZMWL
qcPtJkIP/16WWMq1/dH6Ya8wo4Tc/XZ4mp6TUdcNEVRUqUMdznMHwPYJQyUAJ5VA
8kRjXIXQx/l4kYqCQLyc3VxdKrD+v6w5DV1d9VWQa4SeroXox0CBAk2W6f1NkduG
KvVCzcJx0wOyr+vCZe3hCGKdlf2KsBGppBDQjWkJNMMOxId0BHCr4UumeJgFt6zl
Mym6C6cb8iA0uD3YyCfRSY5C7xW39BDIdeSF/KTp8yu07IBFlKftD45gVZU6Du9d
PQ728+7FIhdZ/FslwjoD8OThXCGN3BJMM2QkZ+YhWGgapEhPGV0laM0yLqSmnlnD
Ro6Dz5xa26AyvEUlJdbPF6cLE5kamj1rTzoUu2HA9N3m48rG5PElmgAKeM6KnCMO
xe3GyczvQeKg/L8I1hcTYh2Vrx5FJ1zLRsTsCddO5GiXyGs6hW9GWA4Fy3fDNqLA
kmRfLndGdVJOuJ55CCVvnuX8D6s2SeWGWYvuHQ43ylhwWBAk8iDNrS6+zGePLaEB
mNAZePF8z137wKTcLZqpnvaIyamT1Qr61dsuH09LAiygm+4iY/muO1NSD4bX/301
yE9h0UfHv/cdP7yvDN64111GCEe9BwNOhYY0Hx0RKFZ39EQAhgERcq3qEpHyBULX
wFRNd2o9W+A100ajfvJRUBkU/uVrN+TtS8rXjb0UCvkcq7gUa13VuQINBFlH/FEB
EACyqe6VypUOyx8yzyvX8UG5vXyGHBVjS+ienZTUgtmJ7ktPRIYZE4B2Cp57/6xg
FdcHH7XtcfcE+AggER2qP6Wp67XQ0v2PxkjQZTBtdxCGQ6LUpNACNKrITuH+xPRY
KWKa2X/XuDdDp6b2wgnz3w8Ysl+SCKqa/0LQ7iscPznW1rhk8Uib4DbOadpsJB4M
ukvOSfoIdWBr2SiuOot5Vxj8+EHWB0vDiduh17DvJgZn3MRuaERYEUVLTMo/2ubs
y4todVztpov2K5Z8/5XNE/wOfYDIutzVxO7SqCeiIIM4q77LprfBtmMRJDLY1ShG
ntSDKzI+g47mcM5e6PKxD6o8vEyYa+ebK7DB8EF1giBF7x8YVd3JjvbCWVnh94lY
qI/7C7UFeHXk5AeoEcO/dThEhMJBY928PVRdj/iX3I5xHezefSkGmi65AXfw4b34
vemXOd7zDBYOwW8drDOOQpoWGIzQehAYBSQyAcjYg3vaf8NC21I1rrIisbRR5o1d
GceEQnXb6EP5D5JVLWhhpxtwPZr2dmknim/zxuXPQUbjxLFOFOyOLQiLlUP2QRFj
mP1M+bTSmKQ4j0bQVAw4v770Ju/BV8uMBrYvyZ/CYWA4fdWX+MBDfdr8URoau8sv
U89pOR2XutqbkniEvvhvA0c7SCSD5ciMnTEjpT3uF5MI/wARAQABiQRyBBgBCgAm
AhsCFiEETHF73A5FL1sTSQQJq0xkxYupw+0FAmZDUAIFCQ7dMi0CQMF0IAQZAQoA
HRYhBCDEK2xBofiHxwiawf7PhdFk6EgrBQJZR/xRAAoJEP7PhdFk6EgrixwQAIJJ
9pBcmatf5esY7FSkakqw7RmCnrfjfQGTnFaxkCavYicDEoUD7YXyg2itaJv8dXUe
EuBf1DrdjdCaff3U20QipgF0fy8Sdb6zwZvNy3ZW144Cd91HON6a0TqiLmPWXgxm
n0QdzIM+8T7OhpZVCZDYTJd0aPncamBNbdU5vyrpxZbXVPApU3YH2guDpav34ilq
338k9IWuPQQIQDe0MxaKVC4EZGB0PuNDcVbS1DtuOE6cPEnfWHJvB4KfmNU+BMNw
J1TTuTF3wXHT7Xyy1UTx/y+U1r20GpBWcfj5ZgTYd1GwK6ebSkb4oRyhC8wKcCnK
7LiTv3tfdB2vPi3LYZKkm8rTK5mkXccBcc3NNt+ypFFyUqmZtXcDz/37pTH5rgFg
dEPTvZUlO5rgRCSsnVqt4tu7J9RzOYI9NUSzJ2C+LV5yGREVJ+UeOjCFZ02oLFsC
LnPZaaIaP9EVYPzDqV1+6HPgyhtj35kgixiwprEkMaQipYQqN15b54HkMhorINUN
rpehie5hKG6D77/nDZo4ArRnZzL2kM0OEFBY19HN6K1vKR1AWx4qzbIgxzDJvt1b
6lCLMol2BgfzFR1bw0P32VXl+v/oQiGEh1AoGzMXButwM526aKCHdDZy+fpcbEM8
VXVrcnqHPH1rr1Ci8caJgRHebzxgAZ1Pb9AopB1ECRCrTGTFi6nD7U74EACdvE7u
pJUzxnlnDgc0vrMWC7oLbX0byjXEX8UR7WoLhphZN66MgJ5amOiPecVfxEe0S8BA
9UA/xfUWRB+v2BkmW6JH+tgSYLQuXh5lrTxZeH7TsPToBe+P/xJxvA2n31VztvLd
EEIveexl0Jj7ifOqUV4/HQ1ZmIpJnBB0Aks5hiu4TALJJ5C+4I452ikNisbDZFSP
9mDQ5RVmYy2kovFdjr+5ILCgsrcJfGT0QAwa0sB4N/RUfFgaEbM62Z7hWaaWq5xx
ozk1X4BpaXZeod0qzcX8/XBOiFkT8gfp5yDk1MXKJ9r3h6SYlpt9kPuMPpiuN8H/
zcKDJdorQL8WaHyCIKeIYHgcjEVZqS4SR2AnKOhXb71tNkVHH64JDUPC34J9fYOY
b3eZqjf05ZH6wGurLuRV4iUsPyNzoq4EbZLkftCbo4VaDXY6sULcSG/927Me7e/s
17BaG4X38MkopYAUXdgBRjaMpUOXTq/Ng+J8Q98Ut93I8MPdKUcfgsW+a99ZoB6v
d+2xecI2g+Db0Oka6or8n3E6r+EipeFldfkYiiMbyR1lANwJebwsu/AWq3NKwbXl
K0mZx5XNAH2ODdRfmInvQUNU6hyoLx48YPn/ytwNxfsO5hKd1E4nBe550np546GW
ehfMw1jKqE9nrfHg6qENOX5fX5kA2qLVQxDALrkCDQRZR/xsARAA5GYEdg2+wYit
/JkGHdpC0OVehiWQyDvbo482z2s5lfloa9tAtXDiLZhfKBdriwJ3FTdHOUUoO80i
xWtV2YOrkr40TvumlRxsN8+ScpP2nvDaL2a2nqHKdaNs9UXbGjESIwHCvUQLWgmo
WwPyn4pOBa9szAJjX1SgT4S7KlGmkxEPYmBsn0mIL5bKUhWiLu+dVg9eRR59yQ8D
WvAC0uf4mddoI4g8E+9Bd2GX65ExeIh45GOvNpiZ5kWJVNWbatyCcMNIyf+awmxG
9QhznG9fRkIzDoXek11JaXIjtorFMNZHhDP/2O/GioUHHS7V1pYuBcemYVGKbok8
lAUOrzf6hacIfx4zXA0Sqp2VJuov3t2xSxJ5Kqs8HSAivXSCTNryIF6gxNYsgR14
fsbbfA6scI9gfrYar2mM7fEap23AeMGf8q1guFiuYz9BB+XqL4DB8LmUg0ATQKZZ
K/4jA/KXk73EZ2TJCsN7fyF0Gy5NXoukZxjsdwgy3vR6yHCp76jyUpQNSoaF5WCF
HTuCNTkh9YK4CvuZ8TxJbWUozfbTLDRCrLZ4AIjyTSgggY1VrtO61UERmAGkhfAS
YMN5a7/S62OuXHR0UpHs4d13kCZlBqT2E9Os6PRtiVOk6nGoZh6GwMaryJBYia+Z
7aoHxrWsJBPeyNxgr3Y+ah/CoWYTQQ0AEQEAAYkCPAQYAQoAJgIbDBYhBExxe9wO
RS9bE0kECatMZMWLqcPtBQJmQ1ASBQkO3TITAAoJEKtMZMWLqcPtnuwQALpjdrFF
OoZ4PJK5wq62f6hAII5ci/L2IyCCf+BXyCkIMS83l+RV62sXiKj2f6mM5MZEVIuA
ydQx8ddestckXnxkuJup2yF/8LAyyR//5mY8u8tv9Dn/hAbRA9l5ItkU4MMpM6Kf
VUraZDKzxGJP91bCD3VMA3KBNek6tIuo81yi5CJNkrGX8ymM7fZ35QWSPFWUXBsk
13fi4P5wIqEMLTN3Wf2IcI2MO8E77sPMKZ43Q+Z5MHNi3OcnuZNMLOSXn4Ccez7N
fTtJAIyCs8ej6A3R3KY0hc8w8rJIKj5qVWfI2p0g0+yVf+ObPF09uICNutXs73fw
DQPD2DMcb7MlCiIHsUC/WSX3BLhY4kC3vJeMsS/+COY8ShT9clcFjuqzVv8WsCfg
lbopl90EBm48X6MNkqK9NGY+TfggNLpjOJGt8ZZ4lUHUyX2v+gTHCPnJwGq8X0Lb
2EVJKvYsF3qOCPgwXCgXPLrkt1YbgPWJSX0nc821A5tayyaVHVGcokZ/xSnJF89O
bztGhGmg7nwwHkovesvg+WH3lshPdKhIGY/rxb5gA81TVfY7ELb/zfbw6RT4Berq
HcrZKIl//NIkgqtqxLkRsFmVvuIbqJ0p6xq52WWlaJoQ9b9hDpg9Yq+3h4ODIxMu
Jax/QaP06etbUiENrr4sehdpayyL8Nf4A98vuQINBFlH/IoBEADWa+Ms+VRM2P+M
0d7SFT+e/CNfcFYHYH1fxMBHz55fwRTRSPufokgcmcC1D6/5oBMnbxKirO+d8bfp
pz0gG/qL9Zbe90E+AekuHckESyWR1EEFGO2LsNTGEmAZYDb9S/RbNQAHxFeA96mc
WwRcaoDVmerpcqYJkKBuD25vCACuuNFD+z82jZQ4olnkoheOSGAv3dRuYG2UoO7A
HBfUy4jcb72IfMex3XpMEF5HVXAW/uRiP7+VjPExg28bTKFbmNYWzeiC9EagdjTJ
sx+DqsJWZTJR/5vB7Vj8kWFaeAwRN/aXhu8EHr1Xp9q2uOO6uLeJ92EPKsOhy+r9
MZmiFJlgFf46zcnPXhhuOserm1fM9ZeDc0BiFp3EaP532rjJCs8vkKXOojoWPFmF
4FM5riXQRLPk+Qib7L5k40GNKzzAtvQS9TJlC3PJCOSuLUCwyvTDcewM67wD7qWQ
1AFhO/dmOUhnhbJeR4m9f+/PGR39ciKduWIvem5LYxoZ24FkVIIbP8UTXc61IiX5
15EDd1GQiHZC2hm3D1tPT0oU4HPFQmj3PiRoNLxByAzcrREU7geuW2y9ulZEPdFk
V2XEiDoeCUxWPyt+6lrmoQ93u9KJ/Ix3oCRZBFUPFMH2w/aG5OPfW5oKY/VCyJ4H
FXH9AvrrbmUPulT6SrUruWCI8WbX3wARAQABiQI8BBgBCgAmAhsgFiEETHF73A5F
L1sTSQQJq0xkxYupw+0FAmZDUCAFCQ7dMfQACgkQq0xkxYupw+2mLQ/+JRxN2v6d
iQa/ye2ttNMuxrMgs0vEpaRfe/yFHTng7xHJCO7MMlX9G8PGVQSbIpZlBzjfhMPX
GP5VX6HUe7p5RO+/7OfkoXBmRe2xs8JlmdewhH36nSLWeo9WOZAq8XnEy8ur/FQ3
Z6mimm63xxquffkG9my6Hpl6zVUNvHG5Y7rNA4k8lGw0dfikMt8Y7SDTiZ8XLN9V
SVbqyS5ByJh7qSAg/BLcceSdg/9v/nb9XXqGMIgMu8HSHdvNxFx5B3Jh1iIa3WnV
khYaoO3BDaGwaud3HAePKlWv4E8Mn3vlD6oKz5pVA9Np7GYlEYxHlZycCsIzFOgc
q7r/WkiTjj98NrgBWmnJF6PsL7xyNz1H2qaJQpuV61rqi6DOjDsejuBmr2o0ofW3
2Z6za9wq9BFta0KXRKO9JayJlwkzfZRFWS3dxMLV+Pgwv1sgr9RYIpeyDj6INMUr
Fbep92Dp88YKYqKdb3H0T8C6xkV/5wG+WrYa9kT5MdAtCKOOlZqBxlQRVOR7kmes
hqvgMdNf+TD4toV8pUrKVjg9Ms7wEd/yQgWU3tl251SVmgU6CuDuN7N7dGA5upzQ
L/jV6qpn+5D2eM/SUL0rsG7lGVF0F58zUF5SWE6XBx0zOMr5ixp6073Rb3BcRv1w
sbtuQwXxQMerUPYi2pIUg/WHzVEYmF6519w=
=TQHl
-----END PGP PUBLIC KEY BLOCK-----
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "/public.asc"
      }}>{`Download Public Key file`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      